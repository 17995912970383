<script>
    let uzuhouse =  'assetes/uzuhouse.jpg';
    let hackz =  'assetes/hackz-hackathon.png';
    let freebie =  'assetes/freebie.png';
</script>

<section class='contents'>
    <h1 class='title'>プレシオ杯</h1>
    <div class='contents-box'>
            <div class='card-box'>
                <a href="https://uzuhouse.com/" class='card sub'>
                    <div class='txt'>
                        <h1><span class='sp'>01.</span>会場</h1>
                        <p>uzuhouse</p>
                        <p>〒750-0003<br />
                            山口県下関市阿弥陀寺町7-8
                        </p>
                    </div>
                    <img src={uzuhouse} class='card-img' alt="uzuhouse">
                </a>
                <h1 class='ex'>01</h1>
            </div>
        <!-- <div class='card-box'>
            <div class='card left'>
                <div class='txt'>
                    <h1><span class='sp'>02.</span>テーマ</h1>
                    <p>xxxxxxxxx</p>
                </div>
                <img src={uzuhouse} class='card-img' alt="テーマ">
            </div>
            <h1 class='ex'>02</h1>
        </div> -->
        <div class='card-box'>
            <div class='card left'>
                <div class='txt'>
                    <h1><span class='sp'>02.</span>景品</h1>
                    <p>今回はハックツカタログ!<br>
                        好きなガジェットを1つ選べます</p>
                </div>
                <img src={freebie} class='card-img' alt="景品">
            </div>
            <h1 class='ex'>02</h1>
        </div>
        <!-- <div class='card-box'>
            <div class='card'>
                <div class='txt'>
                    <h1><span class='sp'>03.</span>優勝景品</h1>
                    <p>モンスター10年分</p>
                </div>
                <img src={uzuhouse} class='card-img' alt="景品">
            </div>
            <h1 class='ex'>03</h1>
        </div>  -->
        <div class='card-box'>
            <!-- leftを入れましょう！！！ -->
            <a href="https://cup.hackz.team/" class='card sub'>
                <div class='txt'>
                    <h1><span class='sp'>03.</span>これまで</h1>
                    <p>過去のハッカソンはこちら</p>
                </div>
                <img src={hackz} class='card-img' alt="過去のハッカソン">
            </a>
            <h1 class='ex'>03</h1>
        </div>
    </div>
    <a class='entry-button'
        href='https://hackz.connpass.com/event/192814/'>
            ハッカソンにエントリー
    </a>
</section>

<style>
    .contents {
        margin-bottom: 100px;
        position: relative;
    }

    .title {
        padding-top: 100px;
        color: #707070;
        text-align: center;
    }

    .title::after {
        width: 28px;
        height: 3px;
        display: block;
        margin: 3px auto 60px;
        background: #707070;
        content: "";
    }

    a {
        text-decoration: none;
        color:rgba(0, 0, 0, 0.89)
    }

    .card-box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 100px 0;
    }

    .card {
        width: 80%;
        max-width: 600px;
        height: auto;
        display: flex;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.54);
        border-radius: 10px;
        align-items: center;
        justify-content: space-between;
        padding: 20px 40px;
    }

    .sub {
        transition: 0.3s;
    }

    .sub:hover {
        transform: translateY(-10px);
        box-shadow: 0 0 10px #fabe00;
    }

    .left {
        order: 2;
    }

    .txt > h1 {
        font-size: 28px;
    }

    .txt > p {
        padding: 10px 0;
        font-weight: bold;
        font-size: 18px;
        color: #707070;
    }

    .card-img {
        width: 200px;
        height: 200px;
        border-radius: 10px;
    }

    .ex {
        font-size: 120px;
        color: rgba(0, 0, 0, 0.87);
        padding: 0 10%;
    }

    .entry-button {
        display: block;
        width: 300px;
        height: 60px;
        margin: 50px auto;
        color: #fabe00;
        font-size: 20px;
        font-weight: bolder;
        text-align: center;
        line-height: 54px;
        background: #fff;
        border: 4px solid #fabe00;
        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.87);
        border-radius: 78px;
        transition: 0.3s;
        user-select: none;
        text-decoration: none;
        z-index: 10;
    }

    .entry-button:hover {
        color: #fff;
        background: #fabe00;
        border: 4px solid #fff;
        box-shadow: 3px 3px 6px #000000 0.87;
    }

    .sp {
        display: none;
    }

    @media screen and (max-width: 1024px) {
        .ex {
            display: none;
        }

        .sp {
            display: inline;
        }

        .card {
            padding: 30px 40px;
        }
    }

    @media screen and (max-width: 896px) {
        .card-box {
            margin: 100px 0;
        }

        .card-img {
            width: 150px;
            height: 150px;
            border-radius: 10px;
        }
    }

    @media screen and (max-width: 700px) {
        .txt > h1 {
            font-size: 24px;
        }

        .txt > p {
            font-size: 16px;
        }
    }


    @media screen and (max-width: 600px) {
        .title {
            font-size: 18px;
        }

        .card-box {
            margin: 80px 0;
        }

        .card {
            flex-flow: column;
            padding: 20px;
        }

        .txt > h1 {
            font-size: 20px;
            text-align: center;
        }

        .txt > p {
            padding: 15px 0;
            font-size: 14px;
            text-align: center;
        }

        .card-img {
            width: 200px;
            height: 200px;
            border-radius: 10px;
        }

        .entry-button {
            width: 250px;
            height: 50px;
            margin: 50px auto;
            font-size: 16px;
            line-height: 44px;
            border: 3px solid #fabe00;
        }
    }
</style>