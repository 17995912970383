<script>
    let hackathon = 'assetes/hackathon.png';
</script>

<section class='about'>
    <div class='back' />
    <h1 class='title'>ハックツハッカソンとは</h1>
    <div class='about-box'>
        <img src={hackathon} class='hackathon' alt="ハックツハッカソン">
        <p class='sp-txt'>
            ハッカソンとは、ソフトウェアのエンジニアリングを指すハック（hack）と
            マラソン（marathon）を組み合わせた造語。<br />
            与えられたテーマに対して短期間チーム開発を行い、その成果を発表するイベントです。
        </p>
        <p>
            ハックツハッカソンは 特に「学生が楽しむこと」「地方にチャンスを作り続けること」に力を入れた
            2日間で開催する学生限定のハッカソンイベントです。
        </p>
        <p>
            多くの学生たちに参加していただき、これまでにハックツハッカソンは計8回開催しました。
        </p>
    </div>
</section>

<style>
    .about {
        position: relative;
        margin-bottom: 100px;
    }

    .back {
        width: 120%;
        height: 820px;
        display: block;
        margin: 3% -10% 0;
        background: #708FDE;
        position: absolute;
        top: 0;
        transform: rotate(-4deg);
        z-index: -1;
        transform-origin: left center;
    }

    .about > h1 {
        margin-top: 100px;
        padding-top: 100px;
        color: #fff;
        text-align: center;
    }

    .about > h1::after {
        width: 28px;
        height: 3px;
        display: block;
        margin: 3px auto 0;
        background: #fff;
        content: "";
    }

    .about-box {
        width: 80%;
        max-width: 900px;
        height: auto;
        min-height: 440px;
        margin: 100px auto;
        padding: 50px 5%;
        background: #fff;
        border-radius: 32px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.54);
    }

    .hackathon {
        width: 85%;
        max-width: 350px;
        display: block;
        margin: 0 auto 40px;
    }

    .about-box > p {
        padding: 10px 0;
        color: #707070;
        font-weight: bold;
        text-align: center;
    }

    @media screen and (max-width: 700px) {
        .about-box {
            padding: 50px 5% 30px;
        }

        .about-box > p {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 600px) {
        .title {
            font-size: 18px;
        }

        .back {
            height: 780px;
        }

        .about-box {
            margin: 50px auto 0;
        }
    }

    @media screen and (max-width: 480px) {
        .hackathon {
            margin: 0 auto 20px;
        }

        .back {
            height: 600px;
        }

        .about-box {
            min-height: 0;
        }

        .sp-txt {
            display: none;
        }
    }
</style>