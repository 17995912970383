<script>
    let TopSrc = 'assetes/plesio.png';
    let SpSrc = 'assetes/plesio-sp.png';
    let logoSrc = 'assetes/plesio-logo.png';
    let calenderSrc = 'assetes/calendar.png';
    let mapSrc = 'assetes/map.png';

    let  y = window.pageYOffset ;
    console.log(y);
</script>

<section class='top'>
    <div class='back' />
    <img src={TopSrc} class='top-img' alt="PLESIO杯">
    <img src={SpSrc} class='top-sp' alt="PLESIO杯">
    <div class='detail'>
        <div class='detail_title'>
          <img src={logoSrc} class='plesio-logo' alt="プレシオ杯 ロゴ" />
          <h3 class='event-title'>PLESIO</h3>
        </div>
        <div class='detail_date'>
            <div class='detail_data'>
                <img src={calenderSrc} class='icon' alt="カレンダー">
                <p class='data-txt'>12/19(土) ~ 12/20(日)</p>
            </div>
            <div class='detail_data'>
                <img src={mapSrc} class='icon' alt="マップ">
                <a class='address' href="https://uzuhouse.com/"><p class='data-txt'>uzuhouse</p></a>
            </div>
        </div>
    </div>
    <a class='entry-button'
        ontouchstart=''
        href='https://hackz.connpass.com/event/192814/'>
    </a>
</section>

<style>
    .top {
        height: 50vw;
        overflow: hidden;
        border-bottom: 17px solid #708FDE;
        position: relative;
        padding: 0;
        margin: 0;
    }

    .back {
        display: none;
    }

    .top-img {
        height: 120%;
        position: relative;
        top: -50px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .top-sp {
        display: none;
    }


    /* .dark {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
    } */

    .detail {
        max-width: 560px;
        width: 100%;
        height: 110px;
        margin: auto;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        top: calc(80% - 70px);
        right: 0;
        background: rgba(255, 255, 255, 1.0);
        border-radius: 86px 0 0 86px;
    }

    .detail_title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 40px;
    }

    .plesio-logo {
        height: 44px;
        margin-right: 10px;
    }

    .event-title {
        font-size: 40px;
    }

    .detail_data {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

    .icon {
        height: 30px;
        padding-right: 10px;
    }

    .data-txt {
        font-size: 20px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.87);
        transition: 0.3s;
    }

    .address {
        text-decoration: none;
    }

    .address:hover > .data-txt {
        color: #fabe00;
    }

    .entry-button {
        display: inline-block;
        width: 350px;
        height: 90px;
        color: #fabe00;
        font-size: 24px;
        font-weight: bolder;
        text-align: center;
        line-height: 78px;
        background: #fff;
        border: 6px solid #fabe00;
        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.87);
        border-radius: 78px;
        position: fixed;
        bottom: 45px;
        right: 56px;
        transition: 0.7s;
        user-select: none;
        text-decoration: none;
        z-index: 10;
    }

    .entry-button::after {
        content: 'ハッカソンにエントリー';
    }

    .entry-button:hover {
        color: #fff;
        background: #fabe00;
        border: 6px solid #fff;
        box-shadow: 3px 3px 6px #000000 0.87;
    }

    .entry-button:hover::after {
        content: 'だが、、、断る！';
    }

    /*タブレット版*/
    @media screen and (max-width: 1024px) {
        .detail {
            max-width: 480px;
            height: 100px;
        }

        .plesio-logo {
            height: 32px;
        }

        .event-title {
            font-size: 32px;
        }

        .icon {
            height: 24px;
        }

        .data-txt {
            font-size: 16px;
        }
    }

    @media screen and (max-width: 896px) {
        .entry-button {
            width: 300px;
            height: 80px;
            font-size: 22px;
            line-height: 72px;
            bottom: 32px;
            right: 24px;
        }
    }

    @media screen and (max-width: 700px) {
        .entry-button {
            width: 260px;
            height: 70px;
            font-size: 18px;
            line-height: 58px;
        }

        .detail {
            max-width: initial;
            width: 100%;
            justify-content: space-around;
            border-radius: 0;
        }
    }

    /*スマホ版*/
    @media screen and (max-width: 600px) {
        .entry-button {
            width: 80%;
            height: 70px;
            bottom: 45px;
            margin: 0 auto;
            right: 50%;
            left: 10%;
        }

        .detail {
            height: 80px;
        }

        .plesio-logo {
            height: 24px;
        }

        .event-title {
            font-size: 24px;
        }

        .icon {
            height: 20px;
        }

        .data-txt {
            font-size: 14px;
        }
    }

    /*スマホ版*/
    @media screen and (max-width: 480px) {
        .top {
            height: 100%;
            border-bottom: 0px;
            overflow: initial;
        }

        .back {
            display: none;
        }

        .top-img {
            display: none;
        }

        .top-sp {
            display: block;
            width: 100%;
        }

        .detail {
            display: none;
        }

    } 
</style>