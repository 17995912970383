<script>
    let wash = 'assetes/wash.png';
    let mask = 'assetes/mask.png';
    let ventilation = 'assetes/ventilation.png';
    let distance = 'assetes/distance.png';
    let measurement = 'assetes/measurement.png';
</script>

<section class='infection'>
    <h1 class='title'>感染症対策</h1>
    <p>本ハッカソンの運営にあたりましては、<br class="sp" />以下の5つの対策を実施しております。</p>
    <p>その他、政府が発表している対策に基づき、<br class="sp" />イベントを運営しております。</p>
    <div class='card-box'>
        <div class='card'>
            <img src={wash} alt="手洗い消毒">
            <p>手洗い消毒</p>
        </div>
        <div class='card'>
            <img src={mask} alt="マスクの着用">
            <p>マスク着用</p>
        </div>
        <div class='card'>
            <img src={ventilation} alt="換気">
            <p>定期的な換気</p>
        </div>
        <div class='card'>
            <img src={distance} alt="ソーシャルディスタンス">
            <p>ソーシャル<br />ディンスタンス</p>
        </div>
        <div class='card'>
            <img src={measurement} alt="検温">
            <p>検温の実施</p>
        </div>
    </div>

</section>

<style>
    .infection {
        margin-bottom: 100px;
        position: relative;
    }

    .title {
        margin-top: 100px;
        padding-top: 100px;
        color: #707070;
        text-align: center;
    }

    .title::after {
        width: 28px;
        height: 3px;
        display: block;
        margin: 3px auto 60px;
        background: #707070;
        content: "";
    }

    .infection > p {
        font-size: 18px;
        font-weight: bold;
        color: #707070;
        text-align: center;
        padding-top: 10px;
    }

    .card-box {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 50px 0;
    }

    .card {
        width: 150px;
        margin: 20px 40px;
    }

    .card > img {
        width: 100%;
        border-radius: 50%;
        border: 4px solid #708FDE;
    }

    .card > p {
        color: rgba(0, 0, 0, 0.54);
        text-align: center;
        padding-top: 16px
    }

    .sp {
        display: none;
    }

    /*タブレット版*/
    @media screen and (max-width: 896px) {
        .title::after {
            margin: 3px auto 30px;
        }

        .card-box {
            padding: 30px 0;
        }

        .card > img {
            width: 90%;
        }

        .card > p {
            font-size: 14px;
        }

        .sp {
            display: inline;
        }
    }

    /*スマホ版*/
    @media screen and (max-width: 600px) {
        .infection > p {
            font-size: 18px;
        }

        .title {
            font-size: 18px;
        }

    }

    @media screen and (max-width: 480px) {
        .infection > p {
            font-size: 16px;
        }

        .card {
            width: 130px;
            margin: 20px;
        }
    }
</style>