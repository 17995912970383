<script>
    let company = 'assetes/hackz-footer.png';
    let mail = 'assetes/mail.png';
    let sns = 'assetes/sns.png';
</script>

<footer>
    <div class='footer'>
        <div class='menu'>
            <nav>
                <img class='company' src={company} alt='株式会社ハックツ'>
                <p class='company-address'>
                    〒810-0073<br />
                    福岡県福岡市中央区<br />
                    舞鶴1丁目9-3 朝日プラザ天神 1204号室
                </p>
                <div class='footer-icon'>
                    <a href='https://forms.gle/L5cFEMcw9QnH7uCG7'>
                        <img class='mail' src={mail} alt='メール'>
                    </a>
                    <a href='https://twitter.com/Hackz_team'>
                        <img class='sns' src={sns} alt='Twitter'>
                    </a>
                </div>
            </nav>
            <a class="menu-privacy" href="https://github.com/hackzinc/docs/blob/master/privacypolicy.md" target="_blank" rel="noopener noreferrer">
                プライバシーポリシー
            </a>
        </div>
        <div class='copy'>
            <p class='copy-txt'>“image: Freepik.com”このカバーのデザインはFreepik.comのリソースを使用しました。</p>
            <p class='copy-txt'>
                当サイトではアクセス動向を把握するため、Google Analyticsを利用しております。<br />
                Google Analyticsは、Cookieを利用してお客様の情報を収集しますが、個人を特定する情報は収集しておりません。
            </p>
            <p class='copyright'>&copy;Hack'z inc</p>
        </div>
    </div>
</footer>

<style>
    footer {
    width: 100%;
    height: 288px;
    padding: 50px 5% 20px;
    position: relative;
    font-size: 12px;
    color: #fff;
    background: #616161;
}

.menu {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 30px;
    border-bottom: 1px solid #fff;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.company {
    height: 60px;
}

.company-address {
    font-size: 14px;
    font-weight: bold;
    margin: 0 15px 0 20px;
}

.footer-icon {
    display: flex;
}

.mail, .sns {
    height: 20px;
    margin: 0 10px;
}

.mail:hover, .sns:hover {
    opacity: 0.7;
}

.copy {
    padding: 20px 0 0;
}

.copy-txt {
    padding: 5px 0;
    text-align: center;
}

.copyright {
    padding: 15px 0;
    font-weight: bold;
    text-align: center;
}

.menu-privacy {
    font-size: 13px;
    color: #fff;
    transition: .3s;
}

.menu-privacy:hover {
    color: #fabe00;
    text-decoration: none;
}

/*タブレット版*/
@media screen and (max-width: 896px) {
    footer {
        height: 240px;
    }

    .menu {
        padding-bottom: 20px;
    }
    
    .company {
        height: 40px;
    }
    
    .company-address {
        font-size: 12px;
        margin: 0 10px;
        width: max-content;
        white-space: nowrap;
    }
    
    .mail, .sns {
        height: 16px;
        margin: 0 5px;
    }

    .menu-privacy  {
        font-size: 10px;
    }
    
    .copy {
        padding: 10px 0 0;
    }
    
    .copy-txt {
        font-size: 10px;
    }
    
    .copyright {
        font-size: 10px;
        padding: 10px 0;
    }
}

@media screen and (max-width: 700px) {
    footer {
        height: 210px;
        padding: 30px 5% 20px;
    }
    
    .company {
        height: 36px;
    }
    
    .company-address {
        font-size: 10px;
    }
    
    .mail, .sns {
        height: 12px;
    }
}

/*スマホ版*/
@media screen and (max-width: 600px) {
    footer {
        height: auto;
        padding: 30px 5% 20px
    }

    .menu {
        display: block;
    }
    
    nav {
        display: block;
        padding: 0 10%;
    }
    
    .company {
        display: block;
        height: 40px;
        margin: 0 auto;
    }
    
    .company-address {
        width: auto;
        white-space: normal;
        display: flex;
        justify-content: center;
        font-size: 10px;
        padding: 15px 0;
    }

    .footer-icon {
        display: flex;
        justify-content: center;
    }
    
    .mail, .sns {
        height: 16px;
    }

    .copy-txt {
        font-size: 8px;
        padding: 5px 10px;
    }
    
    .copyright {
        font-size: 10px;
    }

    .menu-privacy {
        width: 100%;
        display: inline-block;
        padding-top: 10px;
        text-align: center;
    }
}
</style>