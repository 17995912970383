<script>
    let pen = 'assetes/pen.png';
    let theme = 'assetes/theme.png';
    let online = 'assetes/online.png';
    let program = 'assetes/program.png';
</script>

<section class='flow'>
    <div class='back' />
    <h1 class='title'>当日までのフロー</h1>
    <div class='flow-box'>
        <div class='flow-detail'>
            <p>1</p>
            <img src={pen} class='logo' alt="エントリー">
            <div class='detail-txt'>
                <h2>とりあえずエントリー</h2>
                <p>右下のボタンを押すのだ！(チーム登録は後からでもOK!)</p>
            </div>
        </div>
        <div class='triangle' />
        <div class='flow-detail'>
            <p>2</p>
            <img src={theme} class='logo' alt="テーマ">
            <div class='detail-txt'>
                <h2>テーマ発表</h2>
                <p>事前に作り始めていたほうがめちゃめちゃ有利！</p>
            </div>
        </div>
        <div class='triangle' />
        <div class='flow-detail'>
            <p>3</p>
            <img src={online} class='logo' alt="オンライン説明会">
            <div class='detail-txt'>
                <h2>オンライン説明会</h2>
                <p>当日の準備物などの連絡を12/7にオンラインで行うぞ！</p>
            </div>
        </div>
        <div class='triangle' />
        <div class='flow-detail'>
            <p>4</p>
            <img src={program} class='logo' alt="ハッカソン当日">
            <div class='detail-txt'>
                <h2>ハッカソン本番</h2>
                <p>みんなで開発を楽しもう！</p>
            </div>
        </div>
    </div>

</section>
    
<style>
    .flow {
        margin-bottom: 100px;
        position: relative;
    }

    .back {
        width: 140%;
        height: 1050px;
        display: block;
        margin: 3% -20% 0;
        background: #708FDE;
        position: absolute;
        top: 0;
        transform: rotate(-4deg);
        z-index: -1;
        transform-origin: left center;
    }

    .title {
        margin-top: 100px;
        padding-top: 100px;
        color: #fff;
        text-align: center;
    }

    .title::after {
        width: 28px;
        height: 3px;
        display: block;
        margin: 3px auto 0;
        background: #fff;
        content: "";
    }

    .flow-box {
        width: 90%;
        max-width: 750px;
        display: block;
        margin: 100px auto;
        border-radius: 20px;
    }

    .flow-detail {
        width: 100%;
        height: 100px;
        margin: 30px 0;
        display: flex;
        align-items: center;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.89);
    }

    .flow-detail > p {
        width: 100px;
        height: 100px;
        background: #707070;
        border-radius: 10px 0 0 10px;
        text-align: center;
        line-height: 100px;
        font-size: 32px;
        color: #fff;
    }

    .logo {
        width: 100px;
        height: 100px;
        padding: 25px;
        border-right: 1px solid #707070;
        background: #fff;
    }

    .triangle {
        width: 0;
        height: 0;
        display: block;
        margin: 0 auto;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        border-top: 30px solid #fff;
    }

    .detail-txt {
        width: 100%;
        height: 100px;
        padding: 0 30px;
        border-radius: 0 5px 5px 0;
        background: #fff;
        display: flex;
        flex-flow: column;
        justify-content: center;
    }

    .detail-txt > h2 {
        text-align: left;
        margin-bottom: 4px;
    }

    .detail-txt > p {
        color: #707070;
    }

    @media screen and (max-width: 896px) {
        .detail-txt > p {
            font-size: 14px;
        }

        .detail-txt > h2 {
            font-size: 18px;
        }

    }

    @media screen and (max-width: 700px) {
        .triangle {
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid #fff;
        }

        .back {
            height: 1000px;
        }
    }

    @media screen and (max-width: 600px) {
        .title {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 480px) {
        .logo {
            display: none;
        }

        .detail-txt > p {
            font-size: 12px;
        }

        .detail-txt > h2 {
            font-size: 16px;
        }
    }


    /* width: 100%;
    height: 50px;
    background: #707070;
    border-radius: 10px 10px 0 0px;
    text-align: center;
    line-height: 50px;
    font-size: 24px;
    font-weight: bold;
    color: #fff; */
</style>