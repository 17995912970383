<script>
    let alterboth = 'assetes/alterboth.png';
    let excite = 'assetes/excite.png';
    let line = 'assetes/line.png';
    let thwoo = 'assetes/thwoo.png';
    // let jig_jp = 'assetes/jig_jp.png';
</script>

<section class='sponsor'>
    <div class='back' />
    <h1 class='title'>ゴールドスポンサー</h1>
    <div class='sponsor-box'>
        <a href='https://info.excite.co.jp/corp.html'><img src ={excite} class='logo' alt="エキサイト株式会社"></a>
        <a href='https://www.alterbooth.com/'><img src ={alterboth} class='logo' alt="株式会社オルターブース"></a>
        <a href='https://linecorp.com/ja/'><img src ={line} class='logo' alt="LINE株式会社"></a>
        <!-- <img src ={jig_jp} class='logo' alt="株式会社jig.jp"> -->
    </div>
    <h1 class='titles'>ブロンズスポンサー</h1>
    <div class='sponsor-box'>
        <a href='https://thwoo.co.jp/'><img src ={thwoo} class='logo' alt="株式会社スオウ"></a>
    </div>
</section>

<style>
    .sponsor {
        margin-bottom: 100px;
        position: relative;
    }

    .back {
        width: 120%;
        height: 1200px;
        display: block;
        margin: 3% -10% 0;
        background: #708FDE;
        position: absolute;
        top: 0;
        transform: rotate(-4deg);
        z-index: -1;
        transform-origin: left center;
    }

    .title {
        margin-top: 100px;
        padding-top: 100px;
        color: #fff;
        text-align: center;
    }

    .title::after {
        width: 28px;
        height: 3px;
        display: block;
        margin: 3px auto 0;
        background: #fff;
        content: "";
    }

    .titles {
        padding-top: 100px;
        color: #fff;
        text-align: center;
    }

    .titles::after {
        width: 28px;
        height: 3px;
        display: block;
        margin: 3px auto 0;
        background: #fff;
        content: "";
    }

    .sponsor-box {
        width: 80%;
        max-width: 900px;
        height: auto;
        min-height: 300px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 100px auto 0;
        padding: 20px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.54);
    }

    a {
        margin: 0 10px;
    }

    .logo {
        height: 100px;
        transition: 0.3s;
    }

    .logo:hover {
        transform: scale(1.1);
    }

    @media screen and (max-width: 800px) {
        .back {
            height: 1250px;
        }
    }


    @media screen and (max-width: 600px) {
        .back {
            height: 1100px;
        }

        .sponsor-box {
            margin: 50px auto 0;
        }

        .title, .titles {
            font-size: 18px;
        }

        .logo {
            height: 80px;
        }
    }
</style>