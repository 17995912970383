<script>
    let city = 'assetes/city.png';
    let money = 'assetes/money.png';
    let develop = 'assetes/develop.png';

</script>

<section class='recommend'>
    <h1 class='title'>3つの推しポイント</h1>
    <div class='point-box'>
        <div class='point'>
            <img src={city} alt="下関市">
            <h3>今回の舞台は下関！</h3>
            <p>
                今回のハッカソンは、下関で開催！<br />
                海の見える景色で開発をしてみませんか？<br />
                そして、みんなで開発を楽しみましょう！！！！
            </p>
        </div>
        <div class='point'>
            <img src={money} alt="参加費無料">
            <h3>参加費無料！</h3>
            <p>
                参加される学生は基本的に無料です。<br />
                宿泊・食事代等の費用は運営側で全額負担！<br />
                (交通費支給はありません。)
            </p>
        </div>
        <div class='point'>
            <img src={develop} alt="開発">
            <h3>開発を楽しもう！</h3>
            <p>
                自由なアイデアと開発力を最大限発揮して、<br />
                最高のプロダクトを開発しましょう！<br />
                いざ、アウトプットの楽しさへ。
            </p>
        </div>
    </div>
</section>
    
<style>
    .recommend {
        margin-bottom: 100px;
    }

    .title {
        padding-top: 100px;
        color: #707070;
        text-align: center;
    }

    .title::after {
        width: 28px;
        height: 3px;
        display: block;
        margin: 3px auto 0;
        background: #707070;
        content: "";
    }

    .point-box {
        margin-top: 70px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .point {
        margin: 30px;
    }

    .point > img {
        width: 120px;
        display: block;
        margin: 0 auto;
    }

    .point > h3 {
        font-size: 24px;
        text-align: center;
        color: rgba(0, 0, 0, 0.89);
        margin-top: 80px;
        margin-bottom: 40px;
    }

    .point > p {
        text-align: center;
        color: #707070;
    }

    @media screen and (max-width: 800px) {
        .point-box {
            margin-top: 40px;
        }

        .point {
            margin: 30px 0 50px;
        }

        .point > img {
            width: 100px;
        }

        .point > h3 {
            font-size: 20px;
            margin-top: 30px;
            margin-bottom: 20px;
        }

        .point > p {
            font-size: 14px;
        }
    }


    @media screen and (max-width: 600px) {
        .title {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 480px) {
        .point {
            margin: 10px 10px 30px;
        }

        .point > p {
            font-size: 12px;
        }
    }
</style>